import React from 'react';
import Layout from "../layouts/pl";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Fade from 'react-reveal/Fade';
import { FaCheck } from 'react-icons/fa';
import PhotoGallery from '../components/Gallery';

const Dziwnowek2 = (props) => {
  const data = useStaticQuery(graphql` 
  query {
    desktopImage: file(relativePath: { eq: "images/photos/A2-2.jpg" }) {
          childImageSharp {
              fluid(quality: 100){
              ...GatsbyImageSharpFluid
              }
          }   
    }
    mobileImage: file(relativePath: { eq: "images/photos/A2-2-mobile.jpg" }) {
      childImageSharp {
          fluid(quality: 50){
          ...GatsbyImageSharpFluid
          }
      }   
    }
  }`);

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ];


  return (
    <Layout location={props.location}>
      <Fade up>
        <section className="area">
          <Img fluid={sources} alt="Dziwnowek apartamenty Amber" />
          <h1 className="title">APPARTEMENT AMBER</h1>
          <div className="content-container">

            <h1>Appartement Amber 2</h1>
            <p>Appartement Amber 2 ist 30 m2 groß und befindet sich im ersten Stock. Es hat ein Wohnzimmer mit einem Sofa, voll eingerichtete Küchenecke, eigenes Schlafzimmer mit Doppelbett, einen Schrank und Fernseher, Badezimmer, einen Flur mit Schrank und Spiegeln und einen Balkon mit Gardenmöbel. Es hat auch einen Autoplatz nr 11 in der Tiefgarage (mit dem Lift nach unten). Balkonblick auf den Hof.</p>

            <h1>Ausrüstung:</h1>
            <ul>
              <li><FaCheck />Klimanlage</li>
              <li><FaCheck />Smart TV im Wohnzimmer</li>
              <li><FaCheck />wi-fi</li>
              <li><FaCheck />Induktionskochfeld (2 Plätze)</li>
              <li><FaCheck />Spülmaschine</li>
              <li><FaCheck />Kaffeemaschine</li>
              <li><FaCheck />elektrischer Wasserkocher</li>
              <li><FaCheck />Toaster </li>
              <li><FaCheck />Kühlschrank mit Gefrierfach</li>
              <li><FaCheck />Mikrowelle</li>
              <li><FaCheck />Besteck (Töpfe, Pfannen, Tassen, Gläser) </li>
              <li><FaCheck />Esstisch für 4 Personen</li>
              <li><FaCheck />Kaffeetisch</li>
              <li><FaCheck />Smart TV Im Schlafzimmer</li>
              <li><FaCheck />Dusche, WC, Waschbecken</li>
              <li><FaCheck />Waschmaschine und Wäschetrockner</li>
              <li><FaCheck />Fön</li>
              <li><FaCheck />Badetücher</li>
              <li><FaCheck />Staubsauger</li>
              <li><FaCheck />Bügeleisen und Bügelbrett</li>
              <li><FaCheck />Windschutz, Sonnenzelt, Spielzeug für Kinder</li>
              <li><FaCheck />Ausziehsofa</li>
              <li><FaCheck />Doppelbett</li>
              <li><FaCheck />großer Schrank</li>
              <li><FaCheck />im Flur Schrank mit Spiegeln</li>
            </ul>

            <p style={{ marginTop: 20 }}>Im Appartement nr 2 akzeptieren wir Tiere.</p>

          </div>

        </section>
      </Fade>


      <Fade up>
        <section className="photos" id="section3">
          <h1>Galeria</h1>
          <div className="photos">
            <PhotoGallery galleryType='apartament2' />
          </div>
        </section>
      </Fade>

    </Layout>
  )
}



export default Dziwnowek2

